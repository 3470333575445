import React from 'react';
import styles from './header.module.scss';
import {useSinglePrismicDocument, PrismicRichText} from "@prismicio/react";
import Menu from "./menu/menu";
import background from "../../assets/jerry-zhang-SJGiS1JzUCc-unsplash.jpg";

const Header = () => {
  const [document] = useSinglePrismicDocument('header');
  return <div>
    <Menu/>
    <div className={styles.HeaderContainer}>
    <div className={styles.Header} data-testid="Header" style={{ backgroundImage: `url(${background})` }}>
    </div>
    <div className={styles.HeaderText}>
      {document && (<PrismicRichText field={document.data.title}/>)
      }
    </div>
    </div>

  </div>

};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
