import React from 'react';
import PropTypes from 'prop-types';
import styles from './menu-item.module.scss';
import {
  usePrismicDocumentByUID,
} from "@prismicio/react";

const MenuItem = (props) => {
  const id = props.id;
  const [document] = usePrismicDocumentByUID('menu-url', id);
  return <a className={styles.MenuItem} data-testid="Menu" href={'#' + document?.uid}> {document?.data?.name}</a>
};

MenuItem.propTypes = {
  id: PropTypes.string
};

MenuItem.defaultProps = {};

export default MenuItem;
