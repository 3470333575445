import React, {useRef, useEffect} from 'react';
import styles from './experience.module.scss';
import {
  useAllPrismicDocumentsByType,
  PrismicText, PrismicImage,
  PrismicRichText
} from "@prismicio/react";
import {asDate} from "@prismicio/helpers";
import 'fullpage.js';

const Experience = () => {

  const imageRef = useRef([]);
  const imageContainerRef = useRef([]);
  const imageWrapper = useRef([]);
  const overlayRef = useRef([]);
  const experienceBlocRef = useRef([]);

  useEffect(() => {

    function handleScroll() {
      const windowHeight = window.innerHeight;
      experienceBlocRef.current.forEach(
      (el) => {
            if (el.getBoundingClientRect().top < windowHeight) {
               el.classList.add(styles.fadeInFromRight);
            }
      });
    }

    let windowHeight = window.innerHeight;

    function update() {
      imageRef.current.forEach(
          (ref, index) => {
            const nextWrapper = imageWrapper.current[index + 1];
            const offset2 = imageContainerRef.current[index].getBoundingClientRect().top;
            const nextOverlay = overlayRef.current[index + 1];
            ref.classList.remove(styles.Next);
            const distance = -offset2;
            if (offset2 < 0 && (imageContainerRef.current[index].offsetHeight
                + offset2) > 0) {
              if (nextWrapper) {
                nextWrapper?.classList.add(styles.Next);
              ref.style.transform = `translateY(${distance}px)`;
              }
              if (nextOverlay) {
                nextOverlay.style.opacity = (1 - (distance / windowHeight)) / 2
                    + 0.1;
              }
            } else {
              if (nextWrapper) {
                nextWrapper?.classList.remove(styles.Next);
              }
              if (nextOverlay) {
                nextOverlay.style.opacity = 0.09;
              }
            }
          }
      )
    }

    function handleResize() {
      windowHeight = window.innerHeight;
    }

    window.addEventListener('resize', handleResize);

    window.addEventListener('scroll', function () {
      requestAnimationFrame(update);
    });
        window.addEventListener('scroll', function () {
      handleScroll();
    });

    update();

    return () => {
      window.removeEventListener('scroll', update);
    };
  }, []);
  let [document] = useAllPrismicDocumentsByType('experience');
  document = document?.sort(
      function (a, b) {
        return asDate(a?.data?.startdate) > asDate(b?.data?.startdate) ? -1 : 1;
      }
  );
  return <div id='experience'>
    <div className={styles.Experience} data-testid="Experience">
      {document?.map(
          (experience, index) => {
            const startDate = asDate(experience?.data?.startdate);
            const endDate = asDate(experience?.data?.enddate);
            return <div id={'experience_' + index}
                        className={styles.ExperienceBloc}>
              <div className={styles.ImageContainer2}>
                <div
                    ref={(element) => imageContainerRef.current[index] = element}
                    className={styles.ImageContainer}>
                  <div ref={(element) => imageWrapper.current[index] = element}
                       className={styles.ImageWrapper}>
                    <PrismicImage
                        ref={(element) => imageRef.current[index] = element}
                        className={styles.Image}
                        field={experience?.data.image}></PrismicImage>
                  </div>
                  <div
                      ref={(element) => overlayRef.current[index] = element}
                      className={styles.Overlay}></div>
                </div>
              </div>

              <div className={styles.ExperienceSection}
              >
                <div className={styles.header} style={{background: 'linear-gradient(60deg, rgb(46 44 45) 0%, '+
                      experience.data.color + ' 100%)'}}>
                  <div className={styles.innerHeader + ' ' + styles.flex}></div>
                  <div>
                    <svg className={styles.waves} xmlns="http://www.w3.org/2000/svg"
                         xmlnsXlink="http://www.w3.org/1999/xlink"
                         viewBox="0 24 150 28" preserveAspectRatio="none"
                         shapeRendering="auto">
                      <defs>
                        <path id="gentle-wave"
                              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
                      </defs>
                      <g className={styles.parallax}>
                        <use xlinkHref="#gentle-wave" x="48" y="0"
                             fill="rgba(255,255,255,0.7"/>
                        <use xlinkHref="#gentle-wave" x="48" y="3"
                             fill="rgba(255,255,255,0.5)"/>
                        <use xlinkHref="#gentle-wave" x="48" y="5"
                             fill="rgba(255,255,255,0.3)"/>
                        <use xlinkHref="#gentle-wave" x="48" y="7"
                             fill="#fff"/>
                      </g>
                    </svg>
                  </div>
                </div>
                <div
              ref={(element) => experienceBlocRef.current[index] = element}
                >
                            <h3  className={styles.ExperienceTitle}>
                                 <PrismicText field={experience?.data?.title}/>
                                  { startDate && (
                                       <span> {startDate?.getFullYear()}</span>)}
                                  { endDate && (
                                      <span> - {endDate?.getFullYear()} </span>)}
                                </h3>
                                <div className={styles.Text}>
                                  <PrismicRichText
                                      field={experience?.data?.description}></PrismicRichText>
                                </div>
                              <div className={styles.SkillsContainer} style={{color: experience.data.color}}>
                                {experience?.data?.skills.map(
                                    skill => {
                                      return <div className={styles.Skills}><PrismicRichText
                                          field={skill?.skills1}></PrismicRichText>
                                      </div>
                                    }
                                )}
                              </div>
                </div>


              </div>
            </div>
          })
      }
    </div>
  </div>
};

export default Experience;
