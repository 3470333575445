import './App.scss';
import Header from "./components/header/header";
import Experience from "./components/content/experience/experience";
import Presentation from './components/content/presentation/presentation';
import Reference from './components/content/reference/reference';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Presentation></Presentation>
      <Experience></Experience>
      <Reference></Reference>
    </div>
  );
}

export default App;
