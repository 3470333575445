import React from 'react';
import styles from './presentation.module.scss';
import {useSinglePrismicDocument, PrismicRichText, PrismicImage} from "@prismicio/react";

const Presentation = () => {
  const [document] = useSinglePrismicDocument('header-presentation');
 return <div id='presentation' className={styles.Presentation} data-testid="Presentation">
  <div className={styles.Hexagone}>
  <PrismicImage className={styles.Image} field={document?.data?.picture}></PrismicImage>
  </div>
      <div className={styles.Text}>
      <h1>Présentation</h1>
      {document && (<PrismicRichText field={document.data.content}/>)}
      </div>
  </div>
};

Presentation.propTypes = {};

Presentation.defaultProps = {};

export default Presentation;
