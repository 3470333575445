import * as prismic from '@prismicio/client'

// Fill in your repository name
export const repositoryName = 'yanguillaumefuditphu'

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken: 'MC5aRTUweWhBQUFDc0EyMGVy.cRrvv71G77-977-977-9Lu-_ve-_vT3vv70leWrvv73vv71l77-977-977-9fw8WH1Dvv73vv701KO-_ve-_vQ',
  routes: [
  ],
})
