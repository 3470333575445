import React from 'react';
import {
  useAllPrismicDocumentsByType,
  PrismicImage,
  PrismicText,
  PrismicRichText
} from "@prismicio/react";
import styles from './reference.module.scss';

const Reference = () => {  

  let [document] = useAllPrismicDocumentsByType('recommandation');
  return <div className={styles.ReferenceLayout}>
    <h1 >RECOMMANDATIONS</h1>
  <div className={styles.Reference} data-testid="Reference" id='recommandation'>
  {document?.map(
    (recommandation) => {
        return <div className={styles.Recommandation}>
          <div className={styles.Title}>
            <PrismicText
            field={recommandation?.data?.name}></PrismicText>
          </div>
          <div className={styles.Subtitle}>
            <PrismicRichText field={recommandation?.data.poste}></PrismicRichText>         
          </div>  
          <div className={styles.Description}>
            <PrismicRichText field={recommandation?.data.description}></PrismicRichText>         
          </div>     
          <PrismicImage className={styles.Image} field={recommandation?.data.picture}></PrismicImage>
          
          </div> })}
  </div>
  </div>

};

Reference.propTypes = {};

Reference.defaultProps = {};

export default Reference;
