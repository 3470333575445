import React from 'react';
import styles from './menu.module.scss';
import {
  useSinglePrismicDocument
} from "@prismicio/react";
import MenuItem from "../menu-item/menu-item";

const Menu = () => {
  const [document] = useSinglePrismicDocument('menu');
  const items = document?.data['items'];

  return <div className={styles.Menu} data-testid="Menu">
    <ul>
      {items?.map((menuList) => <li>
        <MenuItem
            id={ menuList?.['menu-url'].uid }
        />
      </li>)}
    </ul>
  </div>
};

Menu.propTypes = {};

Menu.defaultProps = {};

export default Menu;
