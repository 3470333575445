import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { PrismicProvider } from '@prismicio/react'
import { client } from './prismic/prismic'

const root = ReactDOM.createRoot(document.getElementById('root'));
const script = document.createElement('script');
script.src = 'https://cdnjs.cloudflare.com/ajax/libs/fullPage.js/3.1.2/fullpage.min.js';
document.head.appendChild(script);

const cssLink = document.createElement('link');
cssLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/fullPage.js/3.1.2/fullpage.min.css';
cssLink.rel = 'stylesheet';
document.head.appendChild(cssLink);


root.render(
  <React.StrictMode>
    <PrismicProvider client={client}>
      <App />
    </PrismicProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
